import Image from "next/legacy/image";
import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { useMobile } from '@/lib/hooks/useMobile';

import { FooterWrapper } from './Footer.styles';



import { DrupalGlobalsFragment } from '@/__generated__/graphql';
import LogoBlaza from './assets/blaza.png';
import LogoEaza from './assets/eaza.png';
import FundraisingLogo from './assets/fundraising-logo.png';

export interface FooterProps {
  menu: DrupalGlobalsFragment['footerLinks'];
  social: DrupalGlobalsFragment['footerSettings']['social'];
  content: DrupalGlobalsFragment['footerSettings']['content'];
  newsletter: DrupalGlobalsFragment['footerSettings']['newsletter'];
  copyright: DrupalGlobalsFragment['footerSettings']['copyright'];
}

export default function Footer({ menu, social, content, newsletter, copyright }: FooterProps): ReactElement {
  const { key } = useTheme();
  //Have to define a custom width for isMobile below
  //because logos won't display properly up to 1320px
  const isMobileLogo = useMobile(1320);
  const showLinks = (key !== 'zsl');
  const zslLink = (key === 'zsl');

  return <FooterWrapper >
    <div className="footer-wrapper">
      <div className="footer-inner">
        {content ? <div className="content-block" dangerouslySetInnerHTML={{__html: content.processed}}></div> : ''}
        <div className="main-block">
          <nav className="menu-block">
            {menu.map((item, key) =>
              <div className="menu-block__item" key={key}>
                <div className="menu-block__item__title">
                  {item.url ? <a href={item.url} target={item.target ? item.target : undefined}>{item.title}</a> : item.title}
                </div>
                <ul className="menu-block__item__links">
                  {item.children && item.children.map((link, key) =>
                    <li key={key}>
                      <a href={link.url} target={link.target ? link.target : undefined}>{link.title}</a>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </nav>
          <div className="promo-block">
            {(newsletter.title || newsletter.callToAction.url) &&
              <div className="newsletter-block">
                <div className="newsletter-block__content">
                  <h2 className="newsletter-block__title">
                    {newsletter.title}
                  </h2>
                  {newsletter.supportingText && <p className="newsletter-block__supporting-block" dangerouslySetInnerHTML={{ __html: newsletter.supportingText }}></p>}
                </div>
                <div className="newsletter-block__cta">
                  <a href={newsletter.callToAction.url} target={newsletter.callToAction.target ? newsletter.callToAction.target : undefined} className="btn btn-primary">
                    {newsletter.callToAction.title}
                  </a>
                </div>
              </div>
            }
            <div className="social-block">
              <div className="social-block__title">
                Connect With Us
              </div>
              <ul className="social-block__links">
                {social.map((link, key) =>
                  <li key={key}>
                    <a href={link.url} className={`social-block__links-${link.type}`} aria-label={link.type}></a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-block">
        <div className="copyright-block__logo">
          <div className="copyright-block__logo-image"></div>
        </div>
        <div className={`copyright-block__info`}>
          <ul className="copyright-block__links">
            {zslLink && (
              <>
                <li>
                  <iframe src="https://registry.blockmarktech.com/certificates/3d7a23c5-ab83-42ee-b918-30c9eb315d86/widget/?tooltip_position=bottom_right&theme=transparent&hover=t" style={{border: "none", height:isMobileLogo ? "58px" : "88px", width: isMobileLogo ? "58px" : "88px", marginLeft: "-8px" }}></iframe>
                </li>
              </>
            )}
            <li>
              <a href="http://www.fundraisingregulator.org.uk/" target="_blank" rel="noreferrer" aria-label="Registered with Fundraising Regulator">
                <Image
                  src={FundraisingLogo}
                  alt="Registered with Fundraising Regulator"
                  layout="fixed"
                  width={isMobileLogo ? 188 : 203}
                  height={isMobileLogo ? 58 : 63}
                />
              </a>
            </li>
            {showLinks && (
              <>
                <li>
                  <a href="https://www.eaza.net/" target="_blank" rel="noreferrer" aria-label="European Association of Zoos and Aquaria">
                    <Image
                      src={LogoEaza}
                      alt="European Association of Zoos and Aquaria"
                      layout="fixed"
                      width={isMobileLogo ? 56 : 80}
                      height={isMobileLogo ? 56 : 80}
                    />
                  </a>
                </li>
                <li>
                  <a href="https://biaza.org.uk/" target="_blank" rel="noreferrer" aria-label="British and Irish Association of Zoos and Aquariums">
                    <Image
                      src={LogoBlaza}
                      alt="British and Irish Association of Zoos and Aquariums"
                      layout="fixed"
                      width={isMobileLogo ? 63 : 88}
                      height={isMobileLogo ? 48 : 68}
                    />
                  </a>
                </li>
              </>
            )}
          </ul>
          <div className="copyright-block__description" dangerouslySetInnerHTML={{__html: copyright.description.processed}}></div>
        </div>
      </div>
    </div>
  </FooterWrapper>;
}
