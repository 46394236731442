import { buttonTemplate, colors, h4, h5, mediaQueries, textTitle } from '@theme/lib';
import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  margin-top: 7.9375rem;

  background: ${(props) => props.theme.colors.footerBgr};
  color: ${colors.white};
  -webkit-mask-image: url(/Footer/bgr-mobile.svg);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: top center;
  -webkit-mask-size: cover;

  ${mediaQueries.lg} {
    -webkit-mask-image: url(/Footer/bgr.svg);
  }

  .footer-wrapper {
    position: relative;
    max-width: 1454px;
    margin: 0 auto;
    z-index: 2;
  }

  .footer-inner {
    padding: 3.8125rem 1.125rem 0;

    ${mediaQueries.lg} {
      padding: 6.125rem 9.8125rem 0;
    }
  }

  .main-block {
    display: grid;
    margin-top: 2rem;
    width: 100%;

    ${mediaQueries.md} {
      grid-template-columns: repeat(5, [col-start] 1fr);
    }

    ${mediaQueries.lg} {
      grid-template-columns: repeat(10, [col-start] 1fr);
      gap: 2rem;
      margin-top: 4rem;
    }

    .menu-block {
      grid-row: 2;

      ${mediaQueries.md} {
        display: grid;
        grid-template-columns: repeat(3, [col-start] 1fr);;
        grid-column: 2 / span 3;
      }

      ${mediaQueries.lg} {
        grid-row: auto;
        grid-column: 1 / span 5;
      }

      &__item {
        margin-top: 2rem;

        ${mediaQueries.md} {
          grid-column: 1 / 1 span;

          &:last-child {
            grid-column: 3 / 1 span;
          }
        }

        ${mediaQueries.lg} {
          margin-top: 0;
        }

        &__title {
          color: ${colors.white};
          ${h4}
          font-weight: 700;

          a {
            color: ${colors.white};
            ${h4}
            font-weight: 700;
          }
        }

        &__links {
          color: ${colors.white};
          list-style: none;
          margin-top: 0.5rem;
          font-weight: 700;

          li {
            margin-top: 1.0625rem;
          }

          a {
            color: ${colors.white};
            font-weight: ${props => props.theme.config.linkWeight};
            font-size: 1rem;
            line-height: 1.2rem;

            &:hover, &:focus {
              text-decoration: underline;
            }

            ${mediaQueries.lg} {
              font-size: 1.25rem;
              line-height: 1.5rem;
            }
          }
        }
      }
    }

    .promo-block {
      display: grid;
      grid-row: 1;

      ${mediaQueries.md} {
        grid-column: 2 / span 3;
      }

      ${mediaQueries.lg} {
        display: block;
        grid-row: auto;
        grid-column: 6 / span 5;
      }
    }
  }

  .social-block {
    grid-row: 1;

    &__title {
      ${textTitle}
    }

    ${mediaQueries.lg} {
      grid-row: auto;
      margin-top: 4.1875rem;
    }

    &__links {
      list-style: none;
      margin-top: 1.573125rem;

      li {
        display: inline-block;
        margin-right: 1.25rem;

        a {
          background: ${colors.white};
          display: inline-block;
          height: 40px;
        }
      }

      &-youtube {
        -webkit-mask-image: url(/Footer/social/youtube.svg);
        -webkit-mask-repeat: no-repeat;
        width: 55px;
      }

      &-twitter {
        -webkit-mask-image: url(/Footer/social/x.svg);
        -webkit-mask-repeat: no-repeat;
        width: 40px;
      }

      &-facebook {
        -webkit-mask-image: url(/Footer/social/facebook.svg);
        -webkit-mask-repeat: no-repeat;
        width: 41px;
      }

      &-instagram {
        -webkit-mask-image: url(/Footer/social/instagram.svg);
        -webkit-mask-repeat: no-repeat;
        width: 40px;
      }

      &-tiktok {
        -webkit-mask-image: url(/Footer/social/tiktok.svg);
        -webkit-mask-repeat: no-repeat;
        width: 35px;
      }
    }
  }

  .newsletter-block {
    background: ${colors.white};
    border-radius: 0;
    color: ${(props) => props.theme.colors.footerNewsletterBlockTextColor};
    padding: 1rem 1.5rem;
    margin-left: -1.125rem;
    margin-top: 1.6875rem;
    width: calc(100% + 2.25rem);
    box-sizing: border-box;
    grid-row: 2;

    ${mediaQueries.md} {
      border-radius: ${props => props.theme.config.componentRadius};
    }

    ${mediaQueries.lg} {
      display: grid;
      grid-row: auto;
      margin: 0;
      width: 100%;
    }

    &__title {
      ${textTitle}
    }

    &__supporting-block {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.2rem;

      ${mediaQueries.lg} {
        font-size: 1.125rem;
        line-height: 1.63125rem;
      }
    }

    &__content {
      grid-column: 1;
    }

    &__supporting-block {
      margin-top: 0.25rem;
    }

    &__cta {
      grid-column: 2;
      margin-top: 1.5rem;

      ${mediaQueries.lg} {
        text-align: right;
        margin-top: 0;
      }

      a {
        ${buttonTemplate}
        color: ${(props) => props.theme.colors.footerNewsletterBlockBtnColor};
        background-color: ${(props) => props.theme.colors.footerNewsletterBlockBtnBgr};
        font-weight: ${props => props.theme.config.linkWeight};
        transition: all 200ms linear;

        &:hover, &:focus {
          text-decoration: none;
          background-color: ${(props) => props.theme.colors.footerNewsletterBlockBtnBgrHover};
          color: ${(props) => props.theme.colors.footerNewsletterBlockBtnColorHover};
        }
      }
    }
  }

  .content-block {
    max-width: 904px;
    margin: 0 auto;
    text-align: center;
    h2,
    h3,
    h4,
    h5 {
      ${h5}
    }

    a {
      color: ${colors.white};
      text-decoration: underline;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .copyright-block {
    display: grid;
    margin-top: 3rem;
    min-height: 45px;
    text-align: center;


    ${mediaQueries.md} {
      grid-template-columns: repeat(5,[col-start] 1fr);
    }

    ${mediaQueries.lg} {
      margin-top: 8.6875rem;
      grid-template-columns: repeat(10, [col-start] 1fr);
      grid-gap: 1rem;
      text-align: left;
    }

    &__logo {
      grid-row: 2;
      position: relative;

      &:before {
        background: ${colors.white};
        -webkit-mask-image: url(/Footer/zsl-footer-logo-circle-mobile.svg);
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position-x: center;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        -webkit-mask-size: cover;
        -webkit-mask-position-x: center;
        height: 100%;
        width: 100%;
        max-width: 360px;

        ${mediaQueries.lg} {
          -webkit-mask-image: url(/Footer/zsl-footer-logo-circle.svg);
          left: 0;
          max-width: none;
        }
      }

      ${mediaQueries.md} {
        grid-column: 1 / span 5;
      }

      ${mediaQueries.lg} {
        grid-row: 1;
        grid-column: 1 / span 5;
        left: 0;
        top: 0;
        width: 100%;
        margin-top: ${(props) => props.theme.key === "zsl" ? '3.5rem' : undefined};
      }

      &-image {
        background: ${colors.white};
        -webkit-mask-image: url(/Footer/zsl-footer-logo2.svg);
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        -webkit-mask-position-y: center;
        max-width: 157px;
        height: 77px;
        margin: 2rem auto 0.5rem;

        ${mediaQueries.lg} {
          margin: 4rem auto 2rem;
        }
      }
    }

    &__info {
      display: grid;
      grid-row: 1;
      align-items: center;

      ${mediaQueries.md} {
        grid-column: 3 / span 1;
        min-width: 325px;
      }

      ${mediaQueries.lg} {
        min-width: none;
        grid-column: 6 / span 5;
        align-items: ${(props) => props.theme.key === "zsl" ? 'end' : undefined};
        margin-bottom: ${(props) => props.theme.key === "zsl" ? '3.5rem' : undefined};
      }
    }

    &__links {
      grid-row: 2;
      list-style: none;
      justify-content: ${(props) => props.theme.key === "zsl" ? 'space-evenly' : 'space-between'};
      margin-bottom: 2.5rem;
      display: ${(props) => props.theme.key === "zsl" ? 'flex' : undefined};
      flex-direction: ${(props) => props.theme.key === "zsl" ? 'row' : undefined};
      ${mediaQueries.lg} {
        flex-direction: ${(props) => props.theme.key === "zsl" ? 'column' : undefined};
        align-self: ${(props) => props.theme.key === "zsl" ? 'end' : undefined};
        align-items:flex-start;
        grid-row: ${(props) => props.theme.key === "zsl" ? 1 : 2 };
        margin-bottom: 0;
        grid-column: 1;
      }

      li {
        display: inline-block;
        margin-right: 1.25rem;
        vertical-align: middle;
        ${mediaQueries.lgLogo} {
          margin-right: 1.8125rem;
        }
        &:first-child {
          display: block;
          margin-bottom: 1.25rem;
          ${mediaQueries.lg} {
            display: inline-block;
            margin-right: 1.25rem;
            margin-bottom: ${(props) => props.theme.key === "zsl" ? '1rem' : '0'};
          }
          ${mediaQueries.lgLogo} {
            margin-right: 1.8125rem;
          }
        }

        a {
          &:after {
            display: none;
          }
        }

        img {
          display: block;
        }

        &:last-child {
          margin-right: 0;
          ${mediaQueries.lg} {
            margin-right: 1.25rem;
          }
          ${mediaQueries.lgLogo} {
            margin-right: 1.8125rem;
          }
        }
      }
    }

    &__description {
      grid-row: 1;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 0.9rem;
      padding: 0.75rem 1.25rem 2rem;
      ${mediaQueries.lg} {
        padding-bottom: 1.5rem;
        grid-column: ${(props) => props.theme.key === "zsl" ? 2 : 1 };
        grid-row: 1;
        font-size: 0.875rem;
        line-height: 1.05rem;
        max-width: 27.25rem;
        padding: 0;
        margin-bottom: ${(props) => props.theme.key === "zsl" ? 0 : '1rem' };
      }

      p {
        font-size: 0.75rem;

        ${mediaQueries.lg} {
          font-size: 0.875rem;
        }
      }
    }
  }
`;
